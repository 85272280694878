import * as React from "react";
import {Button} from './Button';

export default function App(): JSX.Element {

  return (
    <React.Fragment>
      <Button>Generate</Button>
      <Button>Clear</Button>
    </React.Fragment>
  )

}

