import { DefaultTheme } from 'styled-components'

const myTheme: DefaultTheme = {
  borderRadius: '5px',
  background: '#fff',
  colors: {
    main: 'cyan',
    secondary: 'magenta',
  },
}

export default myTheme