import * as React from "react";
import styled from 'styled-components';
import myTheme from '../styles/theme';

export const Button = styled.button`
  background: ${myTheme.colors.main};
  color: ${myTheme.colors.secondary};
  font-size: 1em;
  margin: 1em;
  padding: 0.25em 1em;
  border: 2px solid palevioletred;
  border-radius: 3px;
`;
